import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import { signOutRequest } from "../../store/auth/auth.actions";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import {
  Card, Header, Text, Link
} from "../../components";
import {
  Row, Column, Page
} from "hedron";
import { styles } from "../../lib/styles";
import styled from "styled-components";
import { getSubDomain } from "./lib/getSubDomain";
import { ICustomerConfig } from "src/store/customers/customers.api";
import { getCustomerConfigRequest } from "../../store/customers/customers.actions";
import signUrl from "src/lib/signS3Url";

interface IRouterProps {
  test: string; // This one is coming from the router
}

interface IStateProps {
  customerConfig: ICustomerConfig;
}
interface IProps extends RouteComponentProps<IRouterProps> {
  title: string;
}

interface IDispatchProps {
  signOut: () => void;
  getCustomerConfig: (customerName: string) => void;
}

type Props = IProps & IDispatchProps & IStateProps;

const LogoImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  padding: 10px;
  margin: auto;
`;

const ClientLogoImage = styled.img`
  height: 60px;
  margin-bottom: 15px;
`;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
`;

const CardWrapper = styled.div`
  display: flex;
  height: 75vh;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
    padding: 30px;
    width: auto;
  }

  section {
    padding: 20px;
    @media (max-width: 768px) {
      padding: 10px;
    }
  }
`;

const CardPadding = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 20px 20px 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ClientNameWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 30px auto 20px auto;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 12px;
`;

const Logout: React.FC<Props> = ({
  customerConfig, getCustomerConfig, signOut 
}) => {
  const [customerLogo, setCustomerLogo] = React.useState<string | null>(null);

  React.useEffect(() => {
    const subdomain = getSubDomain(window.location.href);

    if (subdomain) {
      getCustomerConfig(subdomain);
    }
  }, [getCustomerConfig]);

  /**
   * Set the logo image url properly
   */
  const getCustomerLogoUrl = React.useCallback((customerConfig: ICustomerConfig) => {
    if (customerConfig && customerConfig.dark_logo) {
      if (customerConfig.dark_logo.includes("amazonaws")) {
        signUrl(customerConfig.dark_logo).then(signedUrl => {
          setCustomerLogo(signedUrl);
        });
      } else {
        setCustomerLogo(customerConfig.dark_logo);
      }
    }
  }, []);

  React.useEffect(() => {
    if (customerConfig) {
      getCustomerLogoUrl(customerConfig);
    }
  }, [getCustomerLogoUrl, customerConfig]);

  React.useEffect(() => {
    // Ensure the user is actually signed out if they hit this page
    signOut();
  }, [signOut]);

  return (
    <Page
      fluid={true}
      style={{
        background: styles.secondaryDarkColor,
        height: "100vh",
        overflowY: "scroll"
      }}
    >
      <StyledHeader>
        <LogoImage src={"/logo.jpg"} />
      </StyledHeader>
      <CardWrapper>
        <Card>
          <CardPadding>
            {customerConfig &&
                (customerLogo ? (
                  <LogoWrapper>
                    <ClientLogoImage src={customerLogo} />
                  </LogoWrapper>
                ) : (
                  <ClientNameWrapper>{customerConfig.name}</ClientNameWrapper>
                ))}

            <Column fluid={true}>
              <Text
                style={{
                  color: styles.primaryDarkColor,
                  display: "block",
                  textAlign: "center"
                }}
                fontSize="h4"
              >
             Your session was successfully logged out!
              </Text>
            </Column>
            <Column style={{ textAlign: "center" }}>
              <Link to="/">Click here to login!</Link>
            </Column>
          </CardPadding>
        </Card>
      </CardWrapper>
      <Row
        justifyContent="center"
        alignItems="center"
        style={{
          width: "100%",
          textAlign: "center"
        }}
      >
        <Column fluid={true} style={{ margin: "50px 0" }}>
          <Text fontSize="p">Interval Films Timelapse Viewer</Text>
        </Column>
      </Row>
    </Page>
  );
};

const mapStateToProps = (state: IStore): IStateProps => {
  return { customerConfig: state.customers.customerConfig };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return {
    getCustomerConfig: (customerName: string) =>
      dispatch(getCustomerConfigRequest(customerName)),
    signOut: () => dispatch(signOutRequest(false))
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(Logout);
