import { Auth } from "aws-amplify";

export default async (role = "client") => {
  if (role === "client" || role === "admin") {
    try {
      const auth = await Auth.currentSession();

      if (auth) {
        const token = auth.getIdToken().getJwtToken();

        return { Authorization: `Bearer ${token}` };
      }

      return false;
    } catch (error) {
      // Redirect to logout
      window.location.href = "/logout";

      throw { response: { data: { errors: [{ details: "You are not authorized to access this request" }] } } };
    }
  } else {
    return {};
  }
};
