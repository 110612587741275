import * as React from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  Text,
  TextInput,
  Button,
  FormikCheckbox,
  ModalStyles,
  ImageSelectThumbnail,
  RangeSlider,
  DropdownFormik
} from "src/components";
import { Column, Row } from "hedron";
import { CameraLocationHealthStatus, ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { ISite } from "src/store/sites/sites.api";
import { ICustomer } from "src/store/customers/customers.api";
import { siteGetById } from "src/store/sites/sites.getters";
import {
  Formik, FormikProps, Form
} from "formik";
import * as Yup from "yup";
import {
  updateCameraLocationRequest,
  archiveCameraLocationRequest,
  suspendCameraLocationRequest,
  deleteCameraLocationRequest
} from "src/store/camera-locations/camera-locations.actions";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import _ from "lodash";
import { checkUnMigratedImagesRequest } from "src/store/images/images.actions";
import { migrateImagesRequest } from "src/store/images/images.actions";
import Modal from "react-modal";
import { styles } from "src/lib/styles";
import { getVideosRequest } from "src/store/videos/videos.actions";
import moment from "moment-timezone";

import { IVideo, IVideoParams } from "src/types/store/videos";

import { CameraLiveImageSettings } from "src/components/camera-live-image-settings/camera-live-image-settings";
import { CameraLocationVideoSettings } from "src/components/camera-location-video-settings/camera-location-video-settings";
import styled from "styled-components";
import { ImageUploader } from "src/components/image-uploader";
import awsmobile from "src/aws-exports";
import { Storage } from "aws-amplify";

import { toast } from "react-toastify";
import { getCameraLocationRequest } from "src/store/camera-locations/camera-locations.actions";
import { S3Image } from "src/components/s3-image/s3-image";
import { Label } from "react-foundation";
import { delays } from "src/utils/delays";
import { getSitesRequest } from "src/store/sites/sites.actions";
import { getCustomerRequest } from "src/store/customers/customers.actions";
import MobileSpacer from "src/components/MobileSpacer";
import { CameraHealthSettings } from "src/components/camera-health-settings/camera-health-settings";

Modal.setAppElement("#root");

interface ICameraLocationRouterProps {
  cameraLocationUUID: string;
  siteId: string;
  customerId: string;
}

type ICameraLocationProps = RouteComponentProps<ICameraLocationRouterProps>;

interface IStateProps {
  store: IStore;
  cameraLocation: ICameraLocation | null;
  site: ISite | null;
  customer: ICustomer | null;
  isUpdating: boolean;
  cameraLocations: ICameraLocation[];
  unmigrated: number | null;
  isMigrating: boolean;
  videos: IVideo[];
}

interface IState {
  confirmModalOpen: boolean;
  modalType: "delete" | "migrate" | null;
  newMaskUrl?: string;
  hasCheckedUnmigratedImages: boolean;
  newWaterMarkUrl?: string;
  imageWatermarkOpacity: number;
  imageWatermarkKey: string | null;
}

interface ICameraLocationDispatchProps {
  archiveCameraLocation: (cameraLocationId: string, siteId: string) => void;
  deleteCameraLocation: (cameraLocationId: string, siteId: string) => void;
  toggleCameraLocationSuspend: (
    cameraLocationId: string,
    siteId: string
  ) => void;
  checkUnmigrated: (jobRef: string) => void;
  getVideos: (cameraLocationId: string, params: IVideoParams) => void;
  updateCameraLocation: (
    cameraLocationId: string,
    cameraLocation: ICameraLocation
  ) => void;
  migrateImages: (jobRef: string) => void;
  getFullCameraLocation: (cameraLocationId: string) => void;
  getSites: (customerId: string, status?: string, role?: string) => void;
  getCustomer: (customerId: string) => void;
}
type Props = ICameraLocationProps & IStateProps & ICameraLocationDispatchProps;
class EditCameraLocation extends React.Component<Props, IState> {
  public liveCameraSettingsRef: ICameraLocation["live_image_settings"];
  public videoCameraSettingsRef: ICameraLocation["video_settings"];
  public watermarkSettingsRef: ICameraLocation["watermark_settings"];
  private hasHydratedWatermarkStateRef: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      confirmModalOpen: false,
      modalType: null,
      hasCheckedUnmigratedImages: false,
      newWaterMarkUrl: undefined,
      imageWatermarkOpacity: 1,
      imageWatermarkKey: null
    };
  }
  
  public componentDidMount() {
    const {
      cameraLocation, cameraLocations, match 
    } = this.props;

    this.props.getCustomer(match.params.customerId);

    this.props.getSites(
      match.params.customerId, undefined, "admin"
    );

    if (cameraLocation) {
      this.props.getVideos(String(cameraLocation?.id), {});
    }

    if (!cameraLocation || cameraLocation.uuid !== match.params.cameraLocationUUID || !cameraLocation.site || !cameraLocation.site.customer) {
      const newCameraLocation = cameraLocations.find(cl => cl.uuid === match.params.cameraLocationUUID);

      if (newCameraLocation) {
        this.props.getFullCameraLocation(String(newCameraLocation.id));
      }
    }
  }

  public componentDidUpdate() {
    const {
      cameraLocation, checkUnmigrated, unmigrated
    } = this.props;

    if (!this.state.hasCheckedUnmigratedImages && cameraLocation && this.hasLoadedCamera()) {
      if (unmigrated === null) {
        this.setState({ hasCheckedUnmigratedImages: true });
        checkUnmigrated(cameraLocation.job_ref);
      }
    }

    if (!this.hasHydratedWatermarkStateRef) {
      if (cameraLocation?.watermark_settings?.s3Key && !this.state.newWaterMarkUrl) {
        this.setState({
          imageWatermarkKey: cameraLocation.watermark_settings.s3Key,
          imageWatermarkOpacity: cameraLocation.watermark_settings.opacity || 1 
        });
        // ensure this state hydration only happens once, thereafter, local
        // state is the truth and will always be submitted in full
        this.hasHydratedWatermarkStateRef = true;
      }
    }
  }

  private hasLoadedCamera() {
    const { cameraLocation, match } = this.props;

    return !!cameraLocation && cameraLocation.uuid === match.params.cameraLocationUUID;
  }

  public render() {
    const {
      cameraLocation,
      toggleCameraLocationSuspend,
      match,
      videos,
      site,
      customer
    } = this.props;
    
    /**
     * Hide public checkbox when:
     * Either customer or site has full security (Tier 1)
     */
    const hidePublicCheckbox = customer?.security_level || site?.security_level;
    let statusAction = "";
    let healthStatusColour = styles.healthyColour;

    if (cameraLocation) {
      switch (cameraLocation.status) {
        case "active":
          statusAction = "Active";
          break;
        case "archived":
          statusAction = "Archived";
          break;
        case "suspended":
          statusAction = "Suspended";
          break;
      }

      switch (cameraLocation.health_status) {
        case CameraLocationHealthStatus.NoImages:
          healthStatusColour = styles.dangerColour;
          break;
        case CameraLocationHealthStatus.LowVoltage:
          healthStatusColour = styles.warningColour;
          break;
        case CameraLocationHealthStatus.Healthy:
        default:
          healthStatusColour = styles.healthyColour;
          break;
      }
    }

    const {
      customerId, siteId, cameraLocationUUID
    } = this.props.match.params;

    const manualVideoIdx =
      videos && videos.findIndex(vid => vid.type === "manual");

    const editVideoUrl =
      manualVideoIdx > -1 ? `video/${videos[manualVideoIdx].id}` : "new-video";

    if (!this.hasLoadedCamera()) {
      return null;
    }

    return (
      <>
        <div style={{
          background: styles.secondaryDarkColor,
          borderRadius: "15px",
          marginBottom: "80px"
        }}>
          <Row alignContent="space-between">
            <Column>
              <Text fontSize="h1">
                {cameraLocation ? cameraLocation.name : ""}
              </Text>

              {
                cameraLocation && (
                  <div style={{
                    marginLeft: "1rem",
                    display: "inline-block",
                    padding: "0.5rem",
                    borderRadius: "0.5rem",
                    backgroundColor: healthStatusColour,
                    color: cameraLocation.health_status === CameraLocationHealthStatus.LowVoltage ? "black" : "white"
                  }}>
                    {cameraLocation.health_status.replace("_", " ")}
                  </div>
                )
              }
            </Column>
          </Row>
          <Row />
          <Row>
            <Column fluid={true} xs={12} lg={6}>
              {cameraLocation && (
                <Formik
                  initialValues={{
                    delay: cameraLocation.delay,
                    job_ref: cameraLocation.job_ref,
                    latitude: cameraLocation.latitude,
                    longitude: cameraLocation.longitude,
                    timezone: cameraLocation.timezone,
                    name: cameraLocation.name,
                    public: cameraLocation.public === 1,
                    is_finished: cameraLocation.is_finished === 1,
                    mask_enabled: !!cameraLocation.mask_settings?.enabled,
                    watermark_enabled: !!cameraLocation.watermark_settings?.s3Key,
                    minimum_image_frequency: cameraLocation.minimum_image_frequency,
                    minimum_healthy_voltage: cameraLocation.minimum_healthy_voltage,
                    notifications_muted: cameraLocation.notifications_muted === 1,
                    voltage_notifications_muted: cameraLocation.voltage_notifications_muted === 1,
                    ip_camera_url: cameraLocation.ip_camera_url
                  }}
                  onSubmit={(values: EditCameraFormValues) => this.handleSubmit(values)}
                  validationSchema={Yup.object().shape({
                    job_ref: Yup.string().required("Required"),
                    name: Yup.string().required("Required")
                  })}
                >
                  {(props: FormikProps<EditCameraFormValues>) => {
                    const {
                      values, handleChange, handleBlur, setFieldValue
                    } = props;

                    return (
                    
                      <Form>
                        <Row>
                          <Column style={{ paddingTop: 0 }}>
                            <TextInput
                              onChange={handleChange}
                              value={values.name}
                              name="name"
                              placeholder="Name"
                              onBlur={handleBlur}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column>
                            <TextInput
                              onChange={handleChange}
                              value={values.job_ref}
                              name="job_ref"
                              placeholder="Job Ref"
                              onBlur={handleBlur}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column>
                            <TextInput
                              onChange={handleChange}
                              value={values.longitude}
                              name="longitude"
                              placeholder="Longitude"
                              onBlur={handleBlur}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column>
                            <TextInput
                              onChange={handleChange}
                              value={values.latitude}
                              name="latitude"
                              placeholder="Latitude"
                              onBlur={handleBlur}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column>
                            <Label style={{
                              marginBottom: "0.5rem",
                              display: "block" 
                            }}>Timezone</Label>
                            <DropdownFormik
                              name="timezone"
                              placeholder="Timezone"
                              onChange={value => setFieldValue("timezone", value)}
                              items={moment.tz.names().map(timezone => {
                                return {
                                  text: timezone,
                                  value: timezone
                                };
                              })}
                            />
                          </Column>
                        </Row>
                        <Row>
                          {!hidePublicCheckbox && <Column xs={6}>
                            <FormikCheckbox
                              value={values.public}
                              name="public"
                              label="Public"
                            />
                          </Column>}
                          <Column
                            xs={6}
                            style={{ visibility: values.public ? "visible" : "hidden" }}
                          >
                            <Label style={{
                              marginBottom: "0.5rem",
                              display: "block" 
                            }}>Delay</Label>
                            <DropdownFormik
                              name="delay"
                              placeholder="Delay"
                              onChange={value => setFieldValue("delay", value)}
                              items={delays}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column xs={6}>
                            <FormikCheckbox
                              value={values.is_finished}
                              name="is_finished"
                              label="Mark camera as finished?"
                            />
                          </Column>
                        
                        </Row>

                        <CameraHealthSettings formProps={props} />
    
                        <SettingsWrapper>
                          <CameraLocationVideoSettings cameraLocation={cameraLocation} ref={component => {
                            if (component) {
                              this.videoCameraSettingsRef = component.videoCameraSettings;
                            }
                          } }/>
                        </SettingsWrapper>

                        {/* MASKING */}
     
                        <SettingsWrapper>
                          <Row>
                            <Column>
                              <Text fontSize="h3" weight="bold">{"Mask Settings"}</Text>
                            </Column>

                          </Row>
                          <Row>
                            <Column>
                              <FormikCheckbox
                                value={ values.public && values.mask_enabled }
                                name="mask_enabled"
                                label="Enabled"
                              />
                            </Column>
                          </Row>
                          {values.mask_enabled && (
                            <Row alignItems="flex-end">
                              <Column md={8}>
                                <Text fontSize="small">Mask Image (Black areas are blurred)</Text>
                                <ImageUploader
                                  allowedFiles={[
                                    "image/png",
                                    "image/jpg",
                                    "image/jpeg"
                                  ]}
                                  handleUpload={async files => {
                                    if (files) {
                                      try {
                                        const s3Path = await this.uploadToS3(
                                          files[0], cameraLocation, "masks"
                                        );

                                        if (s3Path) {
                                          this.setState({ newMaskUrl: s3Path });
                                          toast.success("Mask applied!");
                                        }
                                      } catch (error) {
                                        console.error(error);
                                        toast.error("Could not upload mask");
                                      }
                                    }
                                  }}
                                  multiple={false}
                                  label="Drag & drop to upload an image mask"
                                />
                              </Column>
                              <Column md={4}>

                                <S3Image s3Url={cameraLocation.mask_settings ? cameraLocation.mask_settings.s3Path : null}/>

                              </Column>
                            </Row>
                          )}
                        </SettingsWrapper>

                        {/* IMAGE WATERMARKING */}
                        <SettingsWrapper>
                          <Row>
                            <Column>
                              <Text fontSize="h3" weight="bold">{"Image Watermark"}</Text>
                            </Column>

                          </Row>
                          <Row>
                            <Column>
                              <FormikCheckbox
                                value={ values.public && values.watermark_enabled }
                                name="watermark_enabled"
                                label="Enabled"
                              />
                            </Column>
                          </Row>
                          {values.watermark_enabled && (
                            <>
                              <Row alignItems="flex-end">
                                <Column md={8}>
                                  <Text fontSize="p">Watermark image (transparent .png)</Text>
                                  <ImageUploader
                                    allowedFiles={["image/png"]}
                                    handleUpload={async files => {
                                      if (cameraLocation) {
                                        if (files) {
                                          try {
                                            const s3Path = await this.uploadToS3(
                                              files[0], cameraLocation, "watermarks"
                                            );

                                            if (s3Path) {
                                              this.setState({ imageWatermarkKey: s3Path });
                                            }
                                          } catch (error) {
                                            console.error(error);
                                            toast.error("Could not upload watermark");
                                          }
                                        }
                                      }
                                    }}
                                    multiple={false}
                                    label="Drag & drop to upload a watermark"
                                  />
                                </Column>
                              </Row>

                              {this.state.imageWatermarkKey && (
                                <>
                                  <Row>
                                    <Column md={8}>
                                      <ImageSelectThumbnail 
                                        opacity={this.state.imageWatermarkOpacity} 
                                        dimensions={this.props.cameraLocation?.video_settings.resize} 
                                      >
                                        <S3Image s3Url={this.state.imageWatermarkKey ?? null} />
                                      </ImageSelectThumbnail>
                                    </Column>
                                    <Column md={4}>
                                      <Button
                                        title="Remove"
                                        aria-label="Remove watermark image"
                                        clickHandler={() => {
                                          this.setState({
                                            imageWatermarkOpacity: 1,
                                            imageWatermarkKey: null
                                          }); // as a reset
                                        }}
                                        text={
                                          <HiOutlineTrash size={20} />
                                        } />
                                    </Column>
                                  </Row>
            
                                  <Row style={{ alignItems: "center" }}>
                                    <Column md={1}>
                                      <Text fontSize="p">Opacity</Text>
                                    </Column>
                                    <Column md={6}>
                                      <RangeSlider min={0.1} max={1} step={0.1} value={this.state.imageWatermarkOpacity} onChange={v => this.setState({ imageWatermarkOpacity: +v.toFixed(1) })} floating={false} />
                                    </Column>
                                  </Row>
                                </>
                              )}
                            </>
                          )}
                        </SettingsWrapper>

                        <CameraLiveImageSettings ref={component => {
                          if (component) {
                            this.liveCameraSettingsRef = component.liveCameraSettings;
                          }
                        } } cameraLocation={cameraLocation} />

                        <Row>
                          <Column>
                          
                            <TextInput
                              onChange={handleChange}
                              value={values.ip_camera_url}
                              name="ip_camera_url"
                              placeholder="IP Camera URL"
                              onBlur={handleBlur}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column>
                            <Button
                              disabled={this.props.isUpdating}
                              text="Save"
                              type="submit"
                            />
                          </Column>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Column>
            <Column fluid={true} xs={12} lg={6}>
              <Row>
                <Column>
                  <Text fontSize="h5">Current Status: <Text fontSize="h5" style={{
                    color: statusAction === "Active" ? styles.primaryAccentColor : styles.secondaryAccentColor,
                    fontWeight: "bold" 
                  }}>{statusAction}</Text></Text>
                  <br />
                  <Button
                    style={{ marginTop: `${styles.paddingUnit}px` }}
                    align="none"
                    text={(statusAction !== "Active" ? "Activate" : "Suspend") + " Camera"}
                    clickHandler={() => {
                      if (cameraLocation) {
                        toggleCameraLocationSuspend(String(cameraLocation?.id), match.params.siteId);
                      } 
                    }}
                  />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Button
                    text={manualVideoIdx > -1 ? "Edit Video" : "Add Video"}
                    clickHandler={() =>
                      this.props.history.push(`/admin/customer/${customerId}/site/${siteId}/camera/${cameraLocationUUID}/${editVideoUrl}`)
                    }
                  />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Button
                    align="none"
                    text="View"
                    newTabPath={`/customer/${match.params.customerId}/site/${match.params.siteId}/camera/${match.params.cameraLocationUUID}/images`}
                    clickHandler={() =>
                      this.props.history.push(`/customer/${match.params.customerId}/site/${match.params.siteId}/camera/${match.params.cameraLocationUUID}/images`)
                    }
                  />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Button
                    text={"Archive or Delete..."}
                    clickHandler={() =>
                      this.setState({
                        confirmModalOpen: true,
                        modalType: "delete"
                      })
                    }
                  />
                </Column>
              </Row>
            </Column>
          </Row>
          <Row />
          {this.renderUnmigrateOption()}
          {cameraLocation && (
            <Modal
              isOpen={this.state.confirmModalOpen}
              onRequestClose={() => this.closeModal()}
              style={ModalStyles("normal", "default")}
            >
              {this.renderModalContent()}
            </Modal>
          )}
        </div>
        <MobileSpacer />
      </>
    );
  }

  private renderModalContent() {
    const { modalType } = this.state;

    const {
      cameraLocation, archiveCameraLocation, match, deleteCameraLocation
    } = this.props;

    if (!this.hasLoadedCamera()) {
      return null;
    }

    if (modalType) {
      if (modalType === "migrate") {
        return (
        
          <React.Fragment>
            {cameraLocation && (
              <Row>
                <Row justifyContent="center">
                  <Column style={{ textAlign: "center" }}>
                    <Text fontSize="h1">{"Are you sure?"}</Text>
                  </Column>
                </Row>
                <Row>
                  <Column style={{ textAlign: "center" }}>
                    <Text>{`This will migrate all images of job reference ${cameraLocation.job_ref} to ${cameraLocation.name}`}</Text>
                  </Column>
                </Row>
                <Row justifyContent="center">
                  <Column md={3} style={{ textAlign: "center" }}>
                    <Button
                      align="none"
                      disabled={this.props.isMigrating}
                      text="Yes"
                      clickHandler={() =>
                        this.handleImageMigration(cameraLocation.job_ref)
                      }
                    />
                  </Column>
                  <Column md={3} style={{ textAlign: "center" }}>
                    <Button
                      align="none"
                      text="Cancel"
                      kind="delete"
                      clickHandler={() => this.closeModal()}
                    />
                  </Column>
                </Row>
              </Row>
            )}
          </React.Fragment>
        );
      } else if (modalType === "delete") {
        return (
          <React.Fragment>
            {cameraLocation && (
              <React.Fragment>
                <Row justifyContent="center">
                  <Column style={{ textAlign: "center" }}>
                    <Text fontSize="h1">{"How do you want to proceed?"}</Text>
                  </Column>
                </Row>
                <Row>
                  <Column style={{ textAlign: "center" }}>
                    <Text>{`Archiving will move all images associated to camera "${cameraLocation.name}" into cold storage. Deleting removes the camera and all images forever.`}</Text>
                  </Column>
                </Row>
                <Row justifyContent="center">
                  <Column md={3} style={{ textAlign: "center" }}>
                    <Button
                      align="none"
                      disabled={this.props.isMigrating || !cameraLocation}
                      text="Delete forever"
                      kind="delete"
                      clickHandler={() =>
                        cameraLocation && deleteCameraLocation(String(cameraLocation.id),
                          match.params.siteId)
                      }
                    />
                  </Column>
                  <Column md={3} style={{ textAlign: "center" }}>
                    <Button
                      align="none"
                      disabled={this.props.isMigrating}
                      text="Archive"
                      clickHandler={() =>
                        cameraLocation && archiveCameraLocation(String(cameraLocation.id),
                          match.params.siteId)
                      }
                    />
                  </Column>
                  <Column md={3} style={{ textAlign: "center" }}>
                    <Button
                      align="none"
                      text="Do nothing"
                      clickHandler={() => this.closeModal()}
                    />
                  </Column>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      }
    }

    return null;
  }

  /**
   * Upload file to s3 bucket
   * @param file
   * @param cameraLocation
   */
  private async uploadToS3(
    file: File, cameraLocation: ICameraLocation, type: "masks" | "watermarks"
  ): Promise<string | null> {
    let result: any;

    try {
      const site = cameraLocation.site;
      const customer = cameraLocation.site && cameraLocation.site.customer;

      if (!cameraLocation || !site || !customer) {
        toast.error("Cannot upload to s3. Missing required info to upload to correct folder.");

        return null;
      }
      const path = `${customer.slug}/${site.slug}/${cameraLocation.job_ref}/${type}/${file.name}`;

      result = await Storage.put(
        path, file, { level: "public" }
      );
    } catch (error) {
      console.error(error);
    }
    const s3BucketPath = `https://${awsmobile.Storage.bucket}.s3.${awsmobile.Storage.region}.amazonaws.com/public/`;

    return `${s3BucketPath}${result.key}`;
  }

  private closeModal() {
    this.setState({ confirmModalOpen: false });
  }

  private async handleImageMigration(jobRef: string) {
    this.setState({ confirmModalOpen: false });
    this.props.migrateImages(jobRef);
  }

  private async handleSubmit(values: EditCameraFormValues) {
    const { updateCameraLocation, cameraLocation } = this.props;

    const updateParams: Partial<ICameraLocation> = {
      name: values.name,
      job_ref: values.job_ref,
      longitude: values.longitude,
      latitude: values.latitude,
      ip_camera_url: values.ip_camera_url,
      timezone: values.timezone,
      delay: values.delay,
      public: values.public ? 1 : 0,
      is_finished: values.is_finished ? 1 : 0,
      minimum_image_frequency: values.minimum_image_frequency,
      minimum_healthy_voltage: values.minimum_healthy_voltage,
      notifications_muted: values.notifications_muted ? 1 : 0,
      voltage_notifications_muted: values.voltage_notifications_muted ? 1 : 0
    };

    const maskS3Path = this.state.newMaskUrl || (cameraLocation && cameraLocation.mask_settings ? cameraLocation.mask_settings?.s3Path : null);

    if (maskS3Path) {
      updateParams.mask_settings = {
        s3Path: maskS3Path,
        enabled: values.mask_enabled,
        blurAmount: 50
      };
    }

    if (this.videoCameraSettingsRef) {
      updateParams.video_settings = this.videoCameraSettingsRef;

      if (updateParams.video_settings.watermark) {
        // dont save the watermark if they didnt updload an image
        if (!updateParams.video_settings.watermark.s3Key) {
          delete updateParams.video_settings.watermark;
        }
      }
    }

    // set from the ref of the live camera image component
    if (this.liveCameraSettingsRef) {
      updateParams.live_image_settings = this.liveCameraSettingsRef;

      if (_.every(updateParams.live_image_settings.admin, test => test?.enabled === false)) {
        updateParams.live_image_settings.admin = null;
      }
    }

    // this will remove the watermark if they unchecked the box
    updateParams.watermark_settings = null;
    
    if (values.watermark_enabled && this.state.imageWatermarkKey) {
      updateParams.watermark_settings = {
        s3Key: this.state.imageWatermarkKey,
        opacity: this.state.imageWatermarkOpacity
      };
    }
    
    if (cameraLocation) {
      updateCameraLocation(String(cameraLocation.id),
        _.extend(cameraLocation, updateParams));
    }
  }

  private renderUnmigrateOption() {
    const { unmigrated, isMigrating } = this.props;

    if (!this.hasLoadedCamera()) {
      return null;
    }

    if (isMigrating) {
      return <Text>Migrating Images...</Text>;
    } else if (unmigrated) {
      return (
        <React.Fragment>
          <Row>
            <Column>
              <Text fontSize="h4">{`There are ${unmigrated} unmigrated images for the assigned job ref:`}</Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Button
                text="Migrate Images"
                clickHandler={() =>
                  this.setState({
                    confirmModalOpen: true,
                    modalType: "migrate"
                  })
                }
              />
            </Column>
          </Row>
        </React.Fragment>
      );
    }

    return null;
  }
}

const mapStateToProps = (state: IStore, props: Props): IStateProps => {
  return {
    store: state,
    site: siteGetById(state, props.match.params.siteId),
    customer: state.customers.currentCustomer,
    cameraLocation: state.cameraLocations.currentCameraLocation,
    cameraLocations: state.cameraLocations.cameraLocations,
    isMigrating: state.images.migratingImages,
    isUpdating: state.sites.updatingSite,
    unmigrated: state.images.unmigrated,
    videos: state.videos.videos
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): ICameraLocationDispatchProps => {
  return {
    getFullCameraLocation: (cameraLocationId: string) => dispatch(getCameraLocationRequest(cameraLocationId)),
    checkUnmigrated: (jobRef: string) =>
      dispatch(checkUnMigratedImagesRequest(jobRef)),
    deleteCameraLocation: (cameraLocationId: string, siteId: string) =>
      dispatch(deleteCameraLocationRequest(cameraLocationId, siteId)),
    archiveCameraLocation: (cameraLocationId: string, siteId: string) =>
      dispatch(archiveCameraLocationRequest(cameraLocationId, siteId)),
    getVideos: (cameraLocationId: string, params: IVideoParams) =>
      dispatch(getVideosRequest(cameraLocationId, params)),
    migrateImages: (jobRef: string) => dispatch(migrateImagesRequest(jobRef)),
    toggleCameraLocationSuspend: (cameraLocationId: string, siteId: string) =>
      dispatch(suspendCameraLocationRequest(cameraLocationId, siteId)),
    updateCameraLocation: (cameraLocationId: string,
      cameraLocation: ICameraLocation) =>
      dispatch(updateCameraLocationRequest(cameraLocationId, cameraLocation)),
    getSites: (
      customerId: string, status?: string, role?: string
    ) => dispatch(getSitesRequest(
      customerId, status, role
    )),
    getCustomer: (customerId: string) => dispatch(getCustomerRequest(customerId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCameraLocation);

const SettingsWrapper = styled.div`
  background: ${styles.secondaryDarkColor};
  margin-top: 3rem;
`;

export interface EditCameraFormValues {
  name: string;
  job_ref: string;
  longitude: number;
  latitude: number;
  timezone: string;
  public: boolean;
  is_finished: boolean;
  delay: number;
  mask_enabled: boolean;
  watermark_enabled: boolean;
  minimum_image_frequency: number;
  minimum_healthy_voltage: number;
  notifications_muted: boolean;
  voltage_notifications_muted: boolean;
  ip_camera_url: string;
}
