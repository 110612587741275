import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import signUrl from "src/lib/signS3Url";
import IntervalLogo from "../../assets/images/logo.jpg";

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  max-width: 300px;
  text-align: "center";

  @media (max-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
`;

const LogoImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const Logo: React.FC<ILogoWrapperProps> = ({ logo }) => {
  const defaultLogo = IntervalLogo;
  const [imgSrc, setImgSrc] = useState<string | undefined>();

  /**
   * Whenever the logo changes (including when it becomes undefined for a new camera),
   * set what's shown by this component to the new logo or fallback
   */
  useEffect(() => {
    if (!logo) {
      setImgSrc(defaultLogo);

      return;
    }

    if (logo?.includes("amazonaws")) {
      signUrl(logo).then(signedUrl => {
        setImgSrc(signedUrl);
      });
    } else {
      setImgSrc(logo);
    }
  }, [defaultLogo, logo]);

  const handleImageError = () => {
    setImgSrc(defaultLogo);
  };

  return (
    <LogoWrapper>
      {imgSrc && <LogoImage src={imgSrc} onError={handleImageError} />}
    </LogoWrapper>
  );
};

interface ILogoWrapperProps {
  logo?: string;
}

export default Logo;
